import ReactMarkdown from "react-markdown";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "../../components/datepicker";
import moment from "moment";
import Header from "../../components/Header";
import ComingSoon from "../../components/ComingSoon";
import Feedback from "../../components/Feedback";
import Reporting from "../Manager/Reporting";
import Template from "./Template";
import managerImage from "../../assets/manager.png";
import weReportLogo from "../../assets/logo/weReporWork.png";
import achiever from "../../assets/Badges/achiever.png";
import clientforce from "../../assets/Badges/clientforce.png";
import communicator from "../../assets/Badges/communicator.png";
import creator from "../../assets/Badges/creator.png";
import learner from "../../assets/Badges/learner.png";
import socialed from "../../assets/Badges/socialed.png";
import star from "../../assets/Badges/star.png";
import strategist from "../../assets/Badges/strategist.png";
import teamwork from "../../assets/Badges/teamwork.png";
import wellness from "../../assets/Badges/wellness.png";
import SummaryIcon from "../../assets/SummaryIcon.png";
import ManagerView from "../Manager/ManagerView";
import React, { useEffect, useState, useRef } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import calenderImage from "../../assets/google/calender.png";
import gmailImage from "../../assets/google/gmail.png";
import driveImage from "../../assets/google/drive.png";
import gitHubImage from "../../assets/coming/github.png";
import jiraImage from "../../assets/coming/Jira.png";
import outlookImage from "../../assets/coming/Outlook.png";
import slackImage from "../../assets/coming/slack.png";
import teamImage from "../../assets/coming/Teams.png";
import linkImage from "../../assets/link-icon.png";
import calImage from "../../assets/cal-icon.png";
import clickImage from "../../assets/click-icon.png";
import userView from "../../assets/user.png";
import teamView from "../../assets/team-view-icon.png";
import EmailList from "./Gmail/EmailList";
import CalenderList from "./Calendar/CalenderList";
import GithubList from "./Github/GithubList";
import DriveList from "./Google Drive/DriveList";
import OutlookList from "./Outlook/OutlookList";
import TeamsList from "./Teams/TeamsList";
import JiraList from "./Jira/JiraList";
import SlackList from "./Slack/SlackList";
import { useSummary } from "../../context/SummaryDataContext";
import { useMount } from "react-use";
import { fetchTemplates } from "../../Store/actions/fetchTemplates";
import { fetchSavedSummary } from "../../Store/actions/fetchSavedSummary";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchUser } from "../../Store/actions/fetchUser";
import { addSummary } from "../../Store/actions/addSummary";
import { fetchUserProfile } from "../../Store/actions/fetchUserProfile";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import { debounce } from "lodash";
import EditSummaryModal from "./EditSummary";
import axios from "axios";
import { renderToString } from "react-dom/server";
import "./Home.css";
import { Box, Typography } from "@mui/material";
import CircleDiagram from "../../components/circleDiagram";
import { useMediaQuery } from "@mui/material";
import FeedbackAccordian from "../../components/FeedbackAccordian";
import { RiGhost2Fill } from "react-icons/ri";

const Home = ({ user }) => {
  const [showSummaryContent, setShowSummaryContent] = useState(true);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [FormatselectedDate, setFormatSelectedDate] = useState(
    moment().format("Do [of] MMM, YYYY")
  );

  const [selectedDateType, setSelectedDateType] = useState(1); // 1 - Today, 2 - Yesterday, 3 - Custom
  const [startDate, setStartDate] = useState(new Date());
  const [IsLoading, setIsLoading] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [managerView, setManagerView] = useState(false);
  const [userPrompt, setUserPrompt] = useState();
  const [summarySaved, setSummarySaved] = useState(false);
  const [responseDriveData, setResponseDriveData] = useState(null);
  const [responseMailData, setResponseMailData] = useState(null);
  const [shortSummary, setShortSummary] = useState(""); // New state for short summary
  const [responseCalenderData, setResponseCalenderData] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const paymentSuccess = urlParams.get("payment_success");
  const textareaRef = useRef(null);
  const { userTemplate } = useSelector((state) => state.templates);
  const [openModal, setOpenModal] = useState(null);
  let { summary } = useSelector((state) => state.savedSummary);
  const { userProfile, loading } = useSelector((state) => state.userProfile);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [severity, setSeverity] = useState("");
  const [isMailResponseLoading, setIsMailResponseLoading] = useState(false);
  const [isCalenderResponseLoading, setIsCalenderResponseLoading] =
    useState(false);
  const [isDriveResponseLoading, setIsDriveResponseLoading] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [edited, setEdited] = useState(false);
  const [allToolSummary, setAllToolSummary] = useState("");
  const [emailSummary, setEmailSummary] = useState();
  const [calenderSummary, setCalenderSummary] = useState();
  const [driveSummary, setDriveSummary] = useState();
  const [outlookSummary, setOutlookSummary] = useState();
  const [teamsSummary, setTeamsSummary] = useState();
  const [jiraSummary, setJiraSummary] = useState();
  const [slackSummary, setSlackSummary] = useState();
  const [githubSummary, setGithubSummary] = useState();
  const [mode, setMode] = useState("manual");
  const [includeSummary, setIncludeSummary] = useState("me");
  
  // NAvigation states
  const [selectedTool, setSelectedTool] = useState("All Tools");
  const [tokenEstimation, setTokenEstimation] = useState(null);
  const [userSavedSummaryData, setUserSavedSummaryData] = useState(null);

  //*****************************
  //****SHORT SUMMARY BADGES*****
  //*****************************
  const [summaryBadges, setsummaryBadges] = useState("");
  const [summaryBreif, setSummaryBrief] = useState();
  const [sumaryTitle, setSummaryTitle] = useState();
  const [badgeTitle, setBadgeTitle] = useState();
  const [badgeUrl, setBadgeUrl] = useState("");
  //BadgesAllocation
  const badges = {
    achiever,
    clientforce,
    communicator,
    creator,
    learner,
    star,
    socialed,
    strategist,
    teamwork,
    wellness,
  };

  const {
    Emails,
    calenderEvents,
    driveList,
    outlookEvents,
    JiraEvents,
    slackEvents,
    teamEvents,
    gitHubEvents,
  } = useSummary();
  // console.log('this is emails in ',Emails)

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const [activeSummurizeBtn, setActiveSummurizeBtn] = useState(true);
  const [apiStatuses, setApiStatuses] = useState({
    gmail: { isConnected: false, api_call_completed: false },
    calender: { isConnected: false, api_call_completed: false },
    drive: { isConnected: false, api_call_completed: false },
    outlook: { isConnected: false, api_call_completed: false },
    teams: { isConnected: false, api_call_completed: false },
    jira: { isConnected: false, api_call_completed: false },
    github: { isConnected: false, api_call_completed: false },
    slack: { isConnected: false, api_call_completed: false },
  });

  const isToolConnected = Object.values(apiStatuses).filter(
    (status) => status.isConnected
  ).length;

  const updateApiStatus = (apiName, status) => {
    setApiStatuses((prevStatuses) => ({
      ...prevStatuses,
      [apiName]: {
        ...prevStatuses[apiName],
        ...status,
      },
    }));
  };

  useEffect(() => {
    const connectedApis = Object.values(apiStatuses).filter(
      (status) => status.isConnected
    );
    const allCompleted = connectedApis.every(
      (status) => status.api_call_completed
    );

    if (connectedApis.length > 0 && allCompleted) {
      setActiveSummurizeBtn(false);
    }   
  }, [apiStatuses]);

  const setPrompt = (userPrompt) => {
    setUserPrompt(userPrompt);
  };

  const setTemplateView = (showTemplate) => {
    setShowTemplate(showTemplate);
  };

  const setYesterdayDate = () => {
    let custDate = moment().subtract(1, "days").format("YYYY-MM-DD");
    setSelectedDate(moment().subtract(1, "days").format("YYYY-MM-DD"));
    setFormatSelectedDate(
      moment().subtract(1, "days").format("Do [of] MMM, YYYY")
    );

    setSelectedDateType(2);
    dispatch(
      fetchSavedSummary({ selectedDate: custDate, userId: user?.data[0]?._id })
    );
    setActiveSummurizeBtn(true);
    setTokenEstimation(null);
  };
  const setTodayDate = () => {
    let custDate = moment().format("YYYY-MM-DD");
    setSelectedDate(moment().format("YYYY-MM-DD"));
    setFormatSelectedDate(moment().format("Do [of] MMM, YYYY"));
    setSelectedDateType(1);
    dispatch(
      fetchSavedSummary({ selectedDate: custDate, userId: user?.data[0]?._id })
    );
    setActiveSummurizeBtn(true);
    setTokenEstimation(null);
  };

  const setCustomDate = (date) => {
    let custDate = moment(date).format("YYYY-MM-DD");
    setSelectedDate(moment(date).format("YYYY-MM-DD"));
    setStartDate(date);
    setSelectedDateType(3);
    setFormatSelectedDate(moment(date).format("Do [of] MMM, YYYY"));
    dispatch(
      fetchSavedSummary({ selectedDate: custDate, userId: user?.data[0]?._id })
    );
    setActiveSummurizeBtn(true);
    setTokenEstimation(null);
  };

  const setIncludeSmry = (include) => {          
    setActiveSummurizeBtn(true);
    setTokenEstimation(null);
    setIncludeSummary(include);
  };

  useMount(() => {
    if (!!!user) {
      window.location.reload();
    } else {
      dispatch(fetchTemplates());
      dispatch(fetchSavedSummary({ selectedDate, userId: user?.data[0]?._id }));
      dispatch(fetchUserProfile());
    }
  });

  useEffect(() => {
    if (paymentSuccess) {
      dispatch(fetchUserProfile());
      const url = new URL(window.location.href);
      url.searchParams.delete("payment_success");
      window.history.replaceState({}, "", url);
    }
  }, [paymentSuccess, dispatch]);

  useEffect(() => {
    if (!userPrompt) {
      setUserPrompt(userTemplate[0]?.description);
      let prompt = `summarize Calender Events and add heading on each section ${userPrompt}`;

      calenderEvents?.forEach((events, index) => {
        prompt +=
          " " + (index + 1) + ". " + (events.subject || "empty") + " --> ";
        prompt += (events.content || "empty") + " ";
      });
    }
  }, [userPrompt, userTemplate]);

  // ***************************
  // ****DELETING PROMO CODE****
  // ***************************

  // useEffect(() => {
  //   const handle = async () => {
  //     const deletePromoCodeUrl =
  //       process.env.REACT_APP_URL + "/auth/delete-promo-code";
  //     const promoCode = localStorage.getItem("promoCode");
  //     try {
  //       const response = await fetch(deletePromoCodeUrl, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ promoCode }),
  //       });
  //       if (response.ok || localStorage.getItem("promoCode")) {
  //         const data = await response.json();
  //       }
  //     } catch (err) {}
  //   };
  //   if (user?.data?.isPromoCode) {
  //     handle();
  //   }
  // }, []);

  // ****************************************
  // *****FETCH BADGES FOR SHORT SUMMARY*****
  // ****************************************
  const fetchBadges = async (summary) => {
    const badgeAllocationURL =
      process.env.REACT_APP_URL + "/openai/badgeAllocation";
    try {
      const response = await axios.post(
        badgeAllocationURL,
        { summary: allToolSummary },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      if (response.status === 200) {
        setsummaryBadges(badges[response.data.data.badge_title]);
        setSummaryBrief(response.data.data.summary_brief);
        setSummaryTitle(response.data.data.summary_title);
        setBadgeTitle(response.data.data.badge_title);
        setBadgeUrl(response.data.data.url);
        dispatch(fetchUserProfile());
      }
    } catch (error) {
      // Handle error
      console.log("Badge allocation error:", error);
      if (error.response) {
        console.error("Server responded with:", error.response.data);
        if (
          error.response.data.message ===
          "Openai response timeout. Please hit Api again"
        )
          fetchBadges();
      }
    }
  };

  // ****************************************
  // ********FETCH DEPRICTIVE SUMMARY********
  // ****************************************

  const handleTokenEstimationClick = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_URL}/openai/summarizeToken`;
      const response = await axios.post(
        apiUrl,
        {
          email: Emails,
          drive: driveList,
          calendar: calenderEvents,
          type: "alltools",
          template: userPrompt,
          github: gitHubEvents || [],
          msteam: teamEvents || [],
          outlook: outlookEvents || [],
          slack: slackEvents || [],
          jira: JiraEvents || [],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );

      if (response.status !== 200) {
      } else {
        const data = response.data.data;
        setTokenEstimation(data); // Update the state with the received data
      }
    } catch (error) {
      console.error("Error during token estimation request:", error);
    }
  };

  const fetchAllToolSummary = async () => {
    try {
      setIsLoading(true);
      setShowSaveButton(true);
      const response = await fetch(
        process.env.REACT_APP_URL + "/openai/summarize",
        {
          method: "POST",
          body: JSON.stringify({
            email: Emails || [],
            drive: driveList || [],
            calendar: calenderEvents || [],
            type: "alltools",
            template: userPrompt,
            github: gitHubEvents || [],
            msteam: teamEvents || [],
            outlook: outlookEvents || [],
            slack: slackEvents || [],
            jira: JiraEvents || [],
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );

      if (response.status === 200) {
        setShowConfirmation(true);
        const data = await response.json();
        setConfirmationMessage("Summary Generated");
        setSeverity("success");
        setAllToolSummary(data?.data?.alltoolSummary);
        setCalenderSummary(data?.data?.calendarSummary);
        setEmailSummary(data?.data?.emailSummary);
        setDriveSummary(data?.data?.driveSummary);
        setJiraSummary(data?.data?.jiraSummary);
        setSlackSummary(data?.data?.slackSummary);
        setGithubSummary(data?.data?.githubSummary);
        setOutlookSummary(data?.data?.outlookSummary);
        setTeamsSummary(data?.data?.msteamSummary);
        fetchBadges();
        const structuredResponse =
          `Date: ${moment(selectedDate).format("Do [of] MMM, YYYY")}\n\n` +
          "Google Mail: \n\n" +
          data.data.botResponse;
        await setResponseMailData(structuredResponse);
        setShowSummaryContent(false);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "workSummarySuccessEvent",
          feature: "Summarize",
        });
      } else {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          event: "workSummaryFailedEvent",
          feature: "Summarize",
        });
      }
    } catch (error) {
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        event: "workSummaryFailedEvent",
        feature: "Summarize",
      });
    } finally {
      setIsMailResponseLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!activeSummurizeBtn) {
      handleTokenEstimationClick();
    }
  }, [activeSummurizeBtn]);

  // ****************************************************
  // ********DESCRIPTIVE SUMMARY SUMMARIZE BUTTON********
  // ****************************************************

  const handleSummarizeClick = async () => {
    if (
      Emails.length === 0 &&
      calenderEvents.length === 0 &&
      driveList.length === 0 &&
      gitHubEvents.length === 0 &&
      teamEvents.length === 0 &&
      outlookEvents.length === 0 &&
      slackEvents.length === 0 &&
      JiraEvents.length === 0
    ) {
      setConfirmationMessage("No data to Summarize");
      setSeverity("error");
      setShowConfirmation(true);
      return;
    }
    setAllToolSummary("");
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "workSummaryRequestedEvent",
      feature: "Summarize",
    });
    fetchAllToolSummary();

    try {
      const promises = [];
      await Promise.all(promises);
      setShowSummaryContent(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleManualSummarizeClick = () => {
    if (
      Emails.length === 0 &&
      calenderEvents.length === 0 &&
      driveList.length === 0 &&
      gitHubEvents.length === 0 &&
      teamEvents.length === 0 &&
      outlookEvents.length === 0 &&
      slackEvents.length === 0 &&
      JiraEvents.length === 0
    ) {
      setConfirmationMessage("No data to Summarize");
      setSeverity("error");
      setShowConfirmation(true);
      return;
    }
    const manualSummary = createDailySummary();
    setShowSummaryContent(false);
    setAllToolSummary(manualSummary);
    setShowSaveButton(true);
    setConfirmationMessage("Manual Summary Generated");
    setSeverity("success");
    setShowConfirmation(true);
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "manualSummarySuccessEvent",
      feature: "Summarize",
    });
  };

  const convertToIST = (backendDate) => {
    const originalDate = new Date(backendDate);
    const istDate = new Date(originalDate.getTime() + 5.5 * 60 * 60 * 1000);
    return istDate.toLocaleTimeString("en-IN", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const convertToIST1 = (backendDate) => {
    const originalDate = new Date(backendDate);
    return originalDate.toLocaleTimeString("en-IN", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  function createDailySummary() {
    let summary =
      "Here’s your daily summary based on the tools connected. Tools where data is not available is omitted altogether.\n";

    if (Emails.length > 0) {
      summary += "\n=== GMAIL EMAIL THREADS ===\n";
      Emails.forEach((email) => {
        summary += `• To: ${email?.to} > ${email.subject} @${convertToIST1(
          email?.time
        )}\n`;
      });
    }

    if (calenderEvents.length > 0) {
      summary += "\n=== CALENDAR EVENTS ATTENDED ===\n";
      calenderEvents.forEach((event) => {
        summary += `• ${event?.id} : ${convertToIST1(
          event?.startTime.dateTime
        )}-${convertToIST1(event?.endTime.dateTime)}\n`;
      });
    }

    if (driveList.length > 0) {
      summary += "\n=== GOOGLE DRIVE FILES UPDATED ===\n";
      driveList.forEach((file) => {
        summary += `• ${file.folder_name} > ${file.file_name}\n`;
      });
    }

    if (outlookEvents.length > 0) {
      summary += "\n=== OUTLOOK EMAIL THREADS ===\n";
      outlookEvents.forEach((email) => {
        summary += `• To: ${email.To} > ${email.Subject} @${convertToIST1(
          email.sentDateTime
        )}\n`;
      });
    }

    if (teamEvents.length > 0) {
      summary += "\n=== TEAMS EVENTS ATTENDED ===\n";
      teamEvents.forEach((event) => {
        summary += `• ${event?.Title} : ${convertToIST(
          event?.startDateTime
        )}-${convertToIST(event?.endDateTime)}\n`;
      });
    }

    if (JiraEvents.length > 0) {
      summary += "\n=== JIRA UPDATES ===\n";
      JiraEvents.forEach((issue) => {
        summary += `• Issue: ${issue.taskName} in project ${issue.project} - ${
          issue.field
        } updated @ ${convertToIST1(issue.date)}\n`;
      });
    }

    if (gitHubEvents.length > 0) {
      summary += "\n=== GITHUB UPDATES ===\n";
      gitHubEvents.forEach((event) => {
        summary += `• ${event.Events} on ${event.Repo} - Action ${event.field1} with Branch/Title = ${event.field2}\n`;
      });
    }

    if (slackEvents.length > 0) {
      summary += "\n=== SLACK CHANNEL INVOLVED ===\n";
      slackEvents.forEach((channel) => {
        summary += `• ${channel.channelName} [${channel.channelType}]\n`;
      });
    }

    return summary;
  }

  useEffect(() => {
    if (
      !showSummaryContent &&
      !shortSummary &&
      !isCalenderResponseLoading &&
      !isDriveResponseLoading &&
      !isMailResponseLoading &&
      showSaveButton
    ) {
    }
  }, [
    showSummaryContent,
    responseMailData,
    responseCalenderData,
    responseDriveData,
    isCalenderResponseLoading,
    isDriveResponseLoading,
    isMailResponseLoading,
    shortSummary,
  ]);

  // ***************************
  // ****** Save SUMMARY ******
  // ***************************
  const saveSummary = async (responseData) => {
    setIsLoading(true);
    dispatch(
      addSummary({
        userId: user?.data[0]?._id,
        summary: responseData,
        date: selectedDate,
        summary_type: "All Tools",
        badgeTitle: badgeTitle,
        badgeSummaryTitle: sumaryTitle,
        badgeSummaryBrief: summaryBreif,
        badgeUrl: badgeUrl,
        shortSummary: shortSummary,
      })
    ).finally(() => {
      setIsLoading(false);
    });
    setShowSaveButton(false);
  };

  useEffect(() => {
    if (summary && summary.length > 0) {
      const selectedDateSummary = summary?.find((ele) => {
        let date = moment(ele?.summarydate).format("YYYY-MM-DD");
        return date == selectedDate;
      });

      if (selectedDateSummary) {
        setShowSummaryContent(false);
        setsummaryBadges(selectedDateSummary?.summaryBadges);
        setSummaryBrief(selectedDateSummary?.badgeSummaryBrief);
        setSummaryTitle(selectedDateSummary?.badgeSummaryTitle);
        setAllToolSummary(selectedDateSummary?.summary);
      } else {
        setShowSummaryContent(true);
        setsummaryBadges(null);
        setSummaryBrief(null);
        setSummaryTitle(null);
        setAllToolSummary(null);
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    let todayDate = new Date();
    todayDate = moment(todayDate).format("YYYY-MM-DD");
    const selectedDateSummary = summary?.find((ele) => {
      let date = moment(ele?.summarydate).format("YYYY-MM-DD");
      return date === selectedDate;
    });
    if (selectedDateSummary) {
      setShowSummaryContent(false);
      setsummaryBadges(selectedDateSummary?.summaryBadges);
      setSummaryBrief(selectedDateSummary?.badgeSummaryBrief);
      setSummaryTitle(selectedDateSummary?.badgeSummaryTitle);
      setAllToolSummary(selectedDateSummary?.summary);
    } else {
      setShowSummaryContent(true);
      setsummaryBadges(null);
      setSummaryBrief(null);
      setSummaryTitle(null);
      setAllToolSummary(null);
    }
  }, [summary]);

  const handleContentChange = debounce((value) => {
    setAllToolSummary(value);
    !edited && setEdited(true);
    !showSaveButton && setShowSaveButton(true);
  }, 300);

  const handleChange = (e) => {
    handleContentChange(e.target.value);
  };

  useEffect(() => {
    // Restore scroll position after updating the textarea content
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [allToolSummary]);

  const handleCopyToClipboard = () => {
    if (allToolSummary) {
      const renderedContent = allToolSummary;
      const formattedContent = renderToString(renderedContent);

      navigator.clipboard
        .writeText(formattedContent)
        .then(() => {
          alert("Copied to clipboard");
        })
        .catch((err) => {
          console.error("Unable to copy to clipboard", err);
        });
    }
  };

  return (
    <div className="py-4">
      <Snackbar
        open={showConfirmation}
        autoHideDuration={4000}
        onClose={() => setShowConfirmation(false)}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit" // Update the color prop to "inherit"
            onClick={() => setShowConfirmation(false)}
          >
            <CloseIcon fontSize="small" color="white" />
          </IconButton>
        }
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={() => setShowConfirmation(false)}
          severity={severity || "success"}
          sx={{ width: "100%" }}
        >
          {confirmationMessage}
        </Alert>
      </Snackbar>

      {/* Logo Branding  */}
      {user?.data[0].role !== "manager" && (
        <div className="flex justify-center items-center gap-3.5">
          <img src={weReportLogo} alt="logo" className="h-10 w-10" />
          <span className="md:text-3xl text-xl font-bold font-fredoka">
            We Report Work
          </span>
        </div>
      )}
      <Header user={userProfile} mode={mode} />
      <div>
        {(IsLoading || loading) && (
          <div className="loader-outer z-99999">
            <div className="loader z-999999"></div>
          </div>
        )}
        <div className="work-summary-section bg-transparent">
          {/*  Conditional rendering between user view and manager view */}
          {managerView ? (
            <ManagerView
              setManagerView={setManagerView}
              selectedDate={selectedDate}
              FormatselectedDate={FormatselectedDate}
            />
          ) : (
            <>
              <div className="flex flex-wrap justify-between ">
                <div className="border-l-8 flex flex-col gap-0 ml-2 border-yellow-500 pl-5 justify-center">
                  {includeSummary == "me"?
                  (<span className="font-semibold text-xl md:text-2xl  ">
                    Your Work Summary
                  </span>)
                  : (<span className="font-semibold text-xl md:text-2xl  ">
                    Your Team Summary 
                  </span>)}
                
                  <span className="text-sm">
                    Connect your work tools & click "Summarize" to see your work
                    summary.
                  </span>
                </div>
                <div className="flex items-center mt-3 ">
                  <div className="">
                    <button
                      onClick={setYesterdayDate}
                      className={`btn py-0 me-2 btn-sm flex   rounded-lg text-black ${
                        selectedDateType === 2 ? "btn-warning" : "text-primary"
                      }`}
                      style={{ height: "30px" }}
                    >
                      {selectedDateType === 2 ? (
                        <FaCheck
                          className="inline"
                          style={{ marginRight: "5px" }}
                        />
                      ) : (
                        ""
                      )}
                      Yesterday
                    </button>
                  </div>
                  <button
                    onClick={setTodayDate}
                    className={`btn  ${
                      selectedDateType === 1 ? "btn-warning" : "text-primary"
                    } py-0 btn-sm text-black`}
                    style={{ height: "30px" }}
                  >
                    {selectedDateType === 1 ? (
                      <FaCheck
                        className="inline"
                        style={{ marginRight: "5px" }}
                      />
                    ) : (
                      ""
                    )}
                    Today
                  </button>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setCustomDate(date);
                    }}
                    customInput={<CustomInput />}
                    placeholderText="Select Date"
                    maxDate={new Date()}
                  />
                </div>
              </div>
              <div
                className={
                  isMobile
                    ? "flex items-center gap-2 bg-white text-black text-[22px] font-semibold font-['Fredoka']  tracking-[0] leading-[55px] h-[110px] rounded-t-xl mt-2 shadow-md"
                    : "flex items-center gap-4 bg-white text-black text-[22px] font-semibold font-['Fredoka']  tracking-[0] leading-[55px] h-[60px] rounded-t-xl mt-2 shadow-md whitespace-nowrap"
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 2,                      
                    }}
                  >
                    <img src={SummaryIcon} alt="img" className="w-7 h-7 ml-5" />
                    <p className="mt-2 summary_heading text-base sm:text-lg md:text-xl lg:text-2xl xl:text-2xl">
                      Summary of {FormatselectedDate} for {user?.displayName}
                    </p>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "auto",
                      display: "flow",
                      alignItems: "center",
                    }}
                  >
                    <FormControl sx={{ width: 160, paddingRight: 1}}>
                    <Select
                        labelId="include-select-label"
                        value={includeSummary}
                        customInput={<CustomInput />}
                        onChange={(e) => {
                          setIncludeSmry(e.target.value);
                        }}
                        label="Include"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      >
                        <MenuItem value="me">Work Summary(Only Me)</MenuItem>
                        <MenuItem value="me_team">Team Summary(Me + Team)</MenuItem>                        
                      </Select>
                  </FormControl>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "auto",
                      display: "flow",
                      alignItems: "center",
                    }}
                  >
                  <FormControl sx={{ width: 160, paddingRight: 1}}>
                  
                     <Select
                        labelId="mode-select-label"
                        value={mode}
                        onChange={(e) => {
                          setMode(e.target.value);
                        }}
                        label="Mode"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      >
                        <MenuItem value="manual">Mode: No LLM</MenuItem>
                        <MenuItem value="credits">Mode: Open AI</MenuItem>
                        <MenuItem value="self" disabled>
                          Mode: Open AI (Self Key) Coming Soon
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </div>
              {summaryBadges && (
                <div className="bg-white shadow-md mt-2 flex flex-col md:flex-row font-'Fredoka' tracking-0">
                  {summaryBadges && (
                    <img
                      className="h-32 w-32 mb-2 md:mb-0 md:mr-2"
                      src={summaryBadges}
                      alt="Badge"
                    />
                  )}
                  <div className="p-4 leading-[30px] tracking-[0]">
                    {sumaryTitle && (
                      <h2 className="font-semibold text-opacity-80 text-black text-[25px] whitespace-pre-wrap mb-2 md:mb-0">
                        {sumaryTitle} :
                      </h2>
                    )}
                    <p className="whitespace-pre-wrap">{summaryBreif}</p>
                  </div>{" "}
                </div>
              )}
              <div
                className={` min-h-[60vh] bg-white mt-2 shadow-md p-4 ${
                  !showSummaryContent && allToolSummary
                    ? "rounded-xl "
                    : " d-flex flex-column justify-content-center align-items-center"
                } mt-1rounded-xl work-summery-box`}
                style={{
                  position: IsLoading || loading ? "inherit" : "relative",
                  opacity: IsLoading || loading ? 0.2 : 1,
                }}
              >
                {showSummaryContent && (
                  <div className="d-flex mb-3 mt-6 ">
                    <img
                      src={gmailImage}
                      className="img-fluid me-2 h-8 w-8"
                      alt=""
                    />
                    <img
                      src={calenderImage}
                      className="img-fluid me-2 h-8 w-8"
                      alt=""
                    />
                    <img
                      src={driveImage}
                      className="img-fluid me-2 h-8 w-8"
                      alt=""
                    />
                    <img
                      src={gitHubImage}
                      className="img-fluid me-2 h-8 w-8"
                      alt=""
                    />
                    <img
                      src={teamImage}
                      className="img-fluid me-2 h-8 w-8"
                      alt=""
                    />
                    <img
                      src={outlookImage}
                      className="img-fluid me-2 h-8 w-8"
                      alt=""
                    />
                    <img
                      src={jiraImage}
                      className="img-fluid me-2 h-8 w-8 hidden sm:flex"
                      alt=""
                    />
                    <img
                      src={slackImage}
                      className="img-fluid me-2 h-8 w-8 hidden sm:flex"
                      alt=""
                    />
                  </div>
                )}

                {showSummaryContent && (
                  <>
                    <Typography
                      sx={{
                        fontFamily: "Fredoka",
                        fontWeight: 800,
                        fontSize: "18px",
                        lineHeight: "30px",
                      }}
                    >
                      Generate your work summary in 3 easy steps!
                    </Typography>
                    {!isMobile && <CircleDiagram />}
                    <Box
                      gap={3}
                      sx={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "row",
                        justifyContent: "space-around",
                        padding: "20px",
                      }}
                    >
                      <div className="step">
                        <div className="step-icon">
                          <img
                            src={linkImage}
                            className="img-fluid me-2 h-6 w-6"
                            alt="Step 1 Icon"
                          />
                        </div>
                        <h3>
                          <span
                            style={{
                              fontFamily: "Fredoka",
                              fontWeight: 400,
                              fontSize: "15px",
                              lineHeight: "30px",
                              color: "#BBBBBB",
                            }}
                          >
                            STEP 1
                          </span>
                          <br />
                          <span style={{ color: "#3D66FB" }}> Connect</span>
                          <br /> your work <br />
                          tools below
                        </h3>
                      </div>
                      <div className="step">
                        <div className="step-icon">
                          <img
                            src={calImage}
                            className="img-fluid me-2 h-6 w-6"
                            alt="Step 2 Icon"
                          />
                        </div>
                        <h3>
                          <span
                            style={{
                              fontFamily: "Fredoka",
                              fontWeight: 400,
                              fontSize: "15px",
                              lineHeight: "30px",
                              color: "#BBBBBB",
                            }}
                          >
                            STEP 2
                          </span>
                          <br /> Select <br /> the{" "}
                          <span style={{ color: "#3D66FB" }}> date</span>
                        </h3>
                      </div>
                      <div className="step">
                        <div className="step-icon">
                          <img
                            src={clickImage}
                            className="img-fluid me-2 h-6 w-6"
                            alt="Step 3 Icon"
                          />
                        </div>
                        <h3>
                          <span
                            style={{
                              fontFamily: "Fredoka",
                              fontWeight: 400,
                              fontSize: "15px",
                              lineHeight: "30px",
                              color: "#BBBBBB",
                            }}
                          >
                            STEP 3
                          </span>
                          <br /> Click <br />{" "}
                          <span style={{ color: "#3D66FB" }}> Summarize</span>
                        </h3>
                      </div>
                    </Box>
                  </>
                )}

                {!showSummaryContent && (
                  <div
                    className="w-100 min-h-[50vh] border-0 bg-white rounded-xl "
                    name="postContent"
                    onChange={handleChange}
                    defaultValue={allToolSummary}
                    key={allToolSummary?.length}
                    readOnly={true} // Remove the readOnly attribute
                  >
                    {allToolSummary && (
                      <div className="font-bold">
                        Detailed Task / Achievement Breakdown{" "}
                      </div>
                    )}
                    <div className="mt-3">
                      <textarea
                        className="w-100 min-h-[50vh] border-0 bg-body rounded-xl"
                        value={allToolSummary}
                        rows={4}
                        cols={50}
                        disabled
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex items-center justify-around shadow-md gap-[1/3] bg-white rounded-br-[30px] rounded-bl-[30px] mt-2 ">
                {!IsLoading && !loading && (
                  <div
                    className={`flex max-md:items-center justify-between flex-row summary_footer  rounded-bl-[30px] rounded-br-[30px] items-center md:p-5 p-2 min-w-full max-md:gap-2 z-0 max-md:mx-auto ${
                      showSummaryContent && !allToolSummary && " "
                    }`}
                  >
                    {allToolSummary && (
                      <div className="flex" data-title="Sharing is great!">
                        <button
                          className="share-button p-2 bg-[#EEE] flex items-center justify-center w-[40px] rounded-[4px]"
                          onClick={() => {
                            handleCopyToClipboard();
                          }}
                        >
                          <ContentCopyRoundedIcon className="share-icon" />
                        </button>
                      </div>
                    )}

                    {!allToolSummary && !isMobile && (
                      <div
                        className="flex share_buttons"
                        style={{ visibility: "hidden" }}
                      >
                        <button className="share-button" />
                        <button className="share-button" />
                        <button className="share-button" />
                        <button className="share-button" />
                      </div>
                    )}
                    {!allToolSummary ? (
                      <div
                        className={
                          isMobile
                            ? "flex flex-column justify-center gap-1 items-center"
                            : "flex flex-column justify-center gap-1 items-center ml-28"
                        }
                      >
                        <div className="flex flex-row gap-2">
                          {mode === "credits" ? (
                            <button
                              className={
                                userProfile?.data[0]?.token[0]
                                  ?.availableCredits < 0.01
                                  ? `bg-[#EA4335] shadow-sm p-2 summary_button text-white rounded-xl flex h-[45px] items-center justify-center font-semibold md:w-[8rem]`
                                  : `bg-[#3d66fb] shadow-sm p-2 summary_button text-white rounded-xl flex h-[45px] items-center justify-center font-semibold md:w-[8rem]`
                              }
                              onClick={handleSummarizeClick}
                              disabled={
                                userProfile?.data[0]?.token[0]
                                  ?.availableCredits < 0.01 ||
                                IsLoading ||
                                activeSummurizeBtn
                              }
                            >
                              {activeSummurizeBtn
                                ? isToolConnected === 0
                                  ? "Summarize"
                                  : "Please Wait.."
                                : "Summarize"}
                            </button>
                          ) : (
                            <button
                              className="bg-[#3d66fb] shadow-sm p-2 summary_button text-white rounded-xl flex h-[45px] items-center justify-center font-semibold md:w-[8rem]"
                              onClick={handleManualSummarizeClick}
                              disabled={activeSummurizeBtn}
                            >
                              {activeSummurizeBtn
                                ? isToolConnected === 0
                                  ? "Summarize"
                                  : "Please Wait.."
                                : "Summarize"}
                            </button>
                          )}
                        </div>
                        {tokenEstimation !== null ? (
                          userProfile?.data[0]?.token[0]?.availableCredits <
                          0.01 ? (
                            <Typography sx={{ color: "#EA4335" }}>
                              Not enough credits, please{" "}
                              <Link
                                to="/pricing"
                                sx={{
                                  color: "#EA4335",
                                  textDecoration: "underline #EA4335",
                                }}
                              >
                                click here
                              </Link>{" "}
                              to explore plans/topup.
                            </Typography>
                          ) : (
                            mode === "credits" && (
                              <Typography sx={{ color: "#3d66fb" }}>
                                [Estimated Credits: ${tokenEstimation}]
                              </Typography>
                            )
                          )
                        ) : isToolConnected === 0 ? (
                          ""
                        ) : (
                          <Typography sx={{ color: "#3d66fb" }}>
                            {" "}
                            Estimating Credits...
                          </Typography>
                        )}
                      </div>
                    ) : (
                      <div className="w-[%50] summarize_button ">
                        {allToolSummary && (
                          <>
                            {summarySaved ? (
                              <div className="flex flex-row gap-2">
                                <button
                                  className={
                                    userProfile?.data[0]?.token[0]
                                      ?.availableCredits < 0.01
                                      ? `bg-[#EA4335] shadow-sm p-2 summary_button text-white rounded-xl flex h-[45px] items-center justify-center font-semibold md:w-[8rem]`
                                      : `bg-[#3d66fb] shadow-sm p-2 summary_button text-white rounded-xl flex h-[45px] items-center justify-center font-semibold md:w-[8rem]`
                                  }
                                  onClick={handleSummarizeClick}
                                  disabled={
                                    userProfile?.data[0]?.token[0]
                                      ?.availableCredits < 0.01 ||
                                    IsLoading ||
                                    activeSummurizeBtn
                                  }
                                >
                                  {activeSummurizeBtn
                                    ? isToolConnected === 0
                                      ? "Summarize"
                                      : "Please Wait.."
                                    : "Summarize"}
                                </button>
                                <button
                                  className="btn btn-success"
                                  style={{
                                    padding: "7px 30px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  Saved
                                </button>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center ">
                                {!showSaveButton ? (
                                  <div className="flex flex-column justify-center gap-2 items-center">
                                    {mode === "credits" ? (
                                      <button
                                        className={
                                          userProfile?.data[0]?.token[0]
                                            ?.availableCredits < 0.01
                                            ? `bg-[#EA4335] shadow-sm p-2 summary_button text-white rounded-xl flex h-[45px] items-center justify-center font-semibold md:w-[8rem]`
                                            : `bg-[#3d66fb] shadow-sm p-2 summary_button text-white rounded-xl flex h-[45px] items-center justify-center font-semibold md:w-[8rem]`
                                        }
                                        onClick={handleSummarizeClick}
                                        disabled={
                                          userProfile?.data[0]?.token[0]
                                            ?.availableCredits < 0.01 ||
                                          IsLoading ||
                                          activeSummurizeBtn
                                        }
                                      >
                                        {activeSummurizeBtn
                                          ? isToolConnected === 0
                                            ? "Summarize"
                                            : "Please Wait.."
                                          : "Summarize"}
                                      </button>
                                    ) : (
                                      <button
                                        className="bg-[#3d66fb] shadow-sm p-2 summary_button text-white rounded-xl flex h-[45px] items-center justify-center font-semibold md:w-[8rem]"
                                        onClick={handleManualSummarizeClick}
                                        disabled={activeSummurizeBtn}
                                      >
                                        {activeSummurizeBtn
                                          ? isToolConnected === 0
                                            ? "Summarize"
                                            : "Please Wait.."
                                          : "Summarize"}
                                      </button>
                                    )}
                                    {tokenEstimation !== null ? (
                                      userProfile?.data[0]?.token[0]
                                        ?.availableCredits < 0.01 ? (
                                        <Typography sx={{ color: "#EA4335" }}>
                                          Not enough credits, please{" "}
                                          <Link
                                            to="/pricing"
                                            sx={{
                                              color: "#EA4335",
                                              textDecoration:
                                                "underline #EA4335",
                                            }}
                                          >
                                            click here
                                          </Link>{" "}
                                          to explore plans/topup.
                                        </Typography>
                                      ) : (
                                        mode === "credits" && (
                                          <Typography sx={{ color: "#3d66fb" }}>
                                            [Estimated Credits: $
                                            {tokenEstimation}]
                                          </Typography>
                                        )
                                      )
                                    ) : isToolConnected === 0 ? (
                                      ""
                                    ) : (
                                      <Typography sx={{ color: "#3d66fb" }}>
                                        {" "}
                                        Estimating Credits...
                                      </Typography>
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {showSaveButton &&
                                user?.data[0]?.currentPlan[0]?.saveSummaries ? (
                                  <button
                                    className="btn btn-secondary mx-3 text-white"
                                    onClick={() => setEdited(true)}
                                  >
                                    Edit
                                  </button>
                                ) : (
                                  <></>
                                )}
                                {showSaveButton &&
                                  user?.data[0]?.currentPlan[0]
                                    ?.saveSummaries && (
                                    <button
                                      className="btn btn-success"
                                      onClick={() =>
                                        saveSummary(allToolSummary)
                                      }
                                    >
                                      Save
                                    </button>
                                  )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    <div className="flex-column right-[1%] max-md:right-[1%] edit_template">
                      <div className=" ">
                        {showTemplate && (
                          <Template
                            handleSummarizeClick={handleSummarizeClick}
                            setTemplateView={setTemplateView}
                            setPrompt={setPrompt}
                            setSeverity={setSeverity}
                            setConfirmationMessage={setConfirmationMessage}
                            setShowConfirmation={setShowConfirmation}
                            prompt={userPrompt}
                          />
                        )}
                      </div>
                      {userProfile?.data[0]?.token[0]?.availableCredits >
                        0.01 && (
                        <button
                          className="uppercase bg-[#EEE] shadow-sm summary_button rounded-xl flex h-fit p-2 items-center justify-center font-semibold md:w-fit sm:w-[6rem] "
                          onClick={() => setShowTemplate(!showTemplate)}
                        >
                          <img
                            src="https://www.freeiconspng.com/uploads/edit-icon-6.png"
                            alt="img"
                            className="img-fluid me-2 h-6 w-6"
                          />
                          <span
                            style={{ fontSize: isMobile ? "12px" : "inherit" }}
                          >
                            Edit Template
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                )}

                {edited && (
                  <EditSummaryModal
                    selectedTool={selectedTool}
                    date={selectedDate}
                    summary={allToolSummary}
                    userId={user?.data[0]?._id}
                    handleClose={(isEdited, editedSummary) => {
                      setEdited(false);
                      if (isEdited) {
                        setSeverity("success");
                        setConfirmationMessage("Summary edited successfully");
                        setShowConfirmation(true);
                        setAllToolSummary(editedSummary);
                        setShowSaveButton(false);
                      }
                    }}
                  />
                )}
              </div>

              {/* Manager Summary  */}
              {/* {user?.data[0]?.role === "manager" ? ( */}
              <div className="">
                <span className="flex justify-between ">
                  <div className="border-[3px] border-[#3d66fb] rounded-full manager_button p-2 items-center  text-center flex mt-2   justify-center  cursor-pointer mb-10 text-sm">
                    <Box
                      gap={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyItems: "center",
                        padding: isMobile ? "0 5px 0 5px" : "0 20px 0 20px",
                        borderRight: "3px solid #3d66fb",
                      }}
                    >
                      {!isMobile && (
                        <img
                          src={userView}
                          alt="icon"
                          className="md:h-8 md:w-8 h-6 w-6"
                        />
                      )}

                      <span
                        className="font-semibold text-sm"
                        style={{ color: "#3d66fb" }}
                      >
                        Individual View
                      </span>
                    </Box>
                    <Box
                      gap={1}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyItems: "center",
                        padding: isMobile ? "0 5px 0 5px" : "0 30px 0 20px",
                      }}
                      onClick={() => setManagerView(!managerView)}
                    >
                      {!isMobile && (
                        <img
                          src={teamView}
                          alt="icon"
                          className="md:h-8 md:w-8 h-6 w-6"
                        />
                      )}

                      <span className="font-semibold text-sm  text-decoration-none no-underline	">
                        Team View
                      </span>
                    </Box>
                  </div>
                  {/* </NavLink> */}
                  <Feedback />
                </span>
                <FeedbackAccordian
                  setSeverity={setSeverity}
                  setConfirmationMessage={setConfirmationMessage}
                  setShowConfirmation={setShowConfirmation}
                />
                <Reporting
                  user={user}
                  setSeverity={setSeverity}
                  setConfirmationMessage={setConfirmationMessage}
                  setShowConfirmation={setShowConfirmation}
                />
              </div>
              <div className="border-l-8 flex flex-col gap-0 ml-1  border-yellow-500 pl-5 justify-center">
                <span className="font-semibold text-xl md:text-2xl  ">
                  Work Summary : Integrate your work tools
                </span>
              </div>

              <EmailList
                summaryData={emailSummary}
                user={user}
                selectedDate={selectedDate}
                includeSummary={includeSummary}
                updateStatus={(status) => updateApiStatus("gmail", status)}
              />
              <CalenderList
                summaryData={calenderSummary}
                user={user}
                selectedDate={selectedDate}
                updateStatus={(status) => updateApiStatus("calender", status)}
              />
              <DriveList
                summaryData={driveSummary}
                user={user}
                selectedDate={selectedDate}
                updateStatus={(status) => updateApiStatus("drive", status)}
              />
              <OutlookList
                user={user}
                selectedDate={selectedDate}
                summaryData={outlookSummary}
                includeSummary={includeSummary}
                updateStatus={(status) => updateApiStatus("outlook", status)}
              />
              <TeamsList
                user={user}
                selectedDate={selectedDate}
                summaryData={teamsSummary}
                updateStatus={(status) => updateApiStatus("teams", status)}
              />
              <JiraList
                user={user}
                selectedDate={selectedDate}
                summaryData={jiraSummary}
                updateStatus={(status) => updateApiStatus("jira", status)}
              />
              <GithubList
                user={user}
                selectedDate={selectedDate}
                summaryData={githubSummary}
                updateStatus={(status) => updateApiStatus("github", status)}
              />
              <SlackList
                user={user}
                selectedDate={selectedDate}
                summaryData={slackSummary}
                includeSummary={includeSummary}
                updateStatus={(status) => updateApiStatus("slack", status)}
              />
            </>
          )}
        </div>
      </div>
      <ComingSoon />
    </div>
  );
};

export default Home;
