import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VscExclude } from "react-icons/vsc";
import { RiArrowUpDownFill } from "react-icons/ri";
import { ImProfile } from "react-icons/im";
import { useSummary } from "../../../context/SummaryDataContext";
import { useDispatch, useSelector } from "react-redux";
import gmailImage from "../../../assets/google/gmail.png";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import FormControlLabel from '@mui/material/FormControlLabel';
import SwitchBtn from "../../../components/SwitchBtn";
import Alert from "@mui/material/Alert";
import { updateUserInfo, fetchUserProfile } from "../../../Store/actions/fetchUserProfile";

import "./Gmail.css";

const EmailList = ({ selectedDate, user, summaryData, includeSummary, updateStatus }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingSentEmails, setLoadingSentEmails] = useState(false);  
  const { Emails, setEmails } = useSummary();
  const { userProfile } = useSelector((state) => state.userProfile);
  const [emailExclude, setEmailExclude] = useState(userProfile?.data[0].gmail);
  const [EmailActiveTab, setEmailActiveTab] = useState("input");
  const [summary, setSummary] = useState([]);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showConnectAlert, setShowConnectAlert] = useState(false);  
  const [isConnected, setIsConnected] = useState(
    userProfile?.data[0]?.gmail?.isConnect
  );
  

  useEffect(() => {
    if (userProfile) {
      setEmailExclude(userProfile?.data[0].gmail);
    }
    if (userProfile?.data[0]?.gmail?.isConnect !== undefined) {
      setIsConnected(userProfile.data[0].gmail.isConnect);
    }
  }, [userProfile]);


  // ***************************
  // **** FETCH GMAIL EMAILS ***
  // ***************************

  const fetchSentEmails = async () => {
    setLoadingSentEmails(true);
    let api_call_completed = false;
    
    const url =
      process.env.REACT_APP_URL +
      "/gmail/emails?date=" +
      selectedDate +
      "&timezoneOffset=" +
      new Date().getTimezoneOffset() +
      "&includeSummary=" + includeSummary;

      console.log("new url : "+url);

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user["connect.sid"]}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        let sentEmails = [];
        if (data.cleanData) {
          data.cleanData.forEach((element) => {
            sentEmails.push({
              to:
                element.payload && element.payload.headers
                  ? element.payload.headers.find((header) => {
                    return header.name === "To";
                  }).value
                  : "",
              subject:
                element.payload && element.payload.headers
                  ? element.payload.headers.find((header) => {
                    return header.name === "Subject";
                  }).value
                  : "",
              content: element.fullBody,
              fullContent: element.fullBody || element.snippet,
              time: element.payload && element.payload.headers
                ? element.payload.headers.find((header) => {
                  return header.name === "Date";
                }).value
                : "",
            });
          });
        }

        setEmails(sentEmails);
      } else {
        console.error("Failed to fetch sent emails:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching sent emails:", error);
    } finally {
      setLoadingSentEmails(false);
      api_call_completed = true;
      updateStatus({ isConnected, api_call_completed });
    }
  };

  // ***************************
  // *** DELETE GMAIL EMAILS ***
  // ***************************

  const handleDeleteEmails = (index) => {
    const updatedEmails = [...Emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };

  const convertToIST = (backendDate) => {
    const originalDate = new Date(backendDate);
    // const istDate = new Date(originalDate.getTime() + 5.5 * 60 * 60 * 1000);
    return originalDate.toLocaleTimeString('en-IN', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  // ***************************
  // *** UPDATE CHECK POINTS ***
  // ***************************


  const updateCheckPoint = async (e) => {
    if (!emailExclude) {
      return;
    }
    let updatedEmailExclude;
    if (e.target.name === "limitLength") {
      const name = e.target.name;
      const value = e.target.checked ? Number(e.target.value) : 14000;
      updatedEmailExclude = { ...emailExclude, [name]: value };
    } else {
      const name = e.target.name;
      const currentValue = emailExclude[name];
      const updatedValue = !currentValue;
      updatedEmailExclude = { ...emailExclude, [name]: updatedValue };
    }
    setEmailExclude(updatedEmailExclude);
    try {
      const url = process.env.REACT_APP_URL + "/auth/updGmail";
      const response = await axios.post(
        url,
        { ...updatedEmailExclude },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );
      if (response.status === 200) {
        await dispatch(fetchUserProfile());
        isConnected && fetchSentEmails();
      } else {
        console.error("API call failed");
      }
    } catch (error) {
      // Handle fetch error here
      console.error("Fetch error:", error);
    }
  };

  const connect = async (isConnected) => {
    try {
      const url = process.env.REACT_APP_URL + "/gmail/connect";
      const response = await axios.put(
        url,
        { isConnect: isConnected },
        {
          headers: {
            Authorization: `Bearer ${user["connect.sid"]}`,
          },
        }
      );

      if (response.status === 200 && isConnected) {
        setIsConnected(true);
        setSnackbarOpen(true)
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionSuccessEvent',
          'feature': 'gmail',
          'status': 'Connected',
        });
      } else if (response.status === 200 && !isConnected) {
        setIsConnected(false);
        setSnackbarOpen(true);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionRemoveEvent',
          'feature': 'gmail',
          'status': 'Removed',
        });
      } else {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
          'event': 'toolConnectionFailedEvent',
          'feature': 'gmail',
          'status': 'Failed'
        });
      }
    } catch (error) {
      // console.error("Fetch error:", error);
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'toolConnectionFailedEvent',
        'feature': 'gmail',
        'status': 'Failed'
      });
    }
  };

  useEffect(() => {
    if (isConnected == true) {
      updateStatus({ isConnected, "api_call_completed": false });
      fetchSentEmails();
    }
    setSummary();
  }, [selectedDate, includeSummary, isConnected]);

  const handleTextareaChangeFrom = (event, index) => {
    const updatedEmails = [...Emails];
    updatedEmails[index].to = event.target.value;
    setEmails(updatedEmails);
  };

  const handleTextareaChangeContent = (event, index) => {
    const updatedEmails = [...Emails];
    updatedEmails[index].content = event.target.value;
    setEmails(updatedEmails);
  };

  const handleTextareaChangeThread = (event, index) => {
    const updatedEmails = [...Emails];
    updatedEmails[index].subject = event.target.value;
    setEmails(updatedEmails);
  }

  const handleGmailConnect = async () => {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'toolConnectionRequestedEvent',
      'feature': 'gmail',
      'status': 'Requested-Connect'
    });
    
    const googleOAuthURL = `${process.env.REACT_APP_URL}/auth/google/gmail?state=${userProfile?.data[0]?._id}`
    const newWindow = window.open(googleOAuthURL, "_self", 'noopener,noreferrer,resizable');

    if (newWindow) {
      newWindow.focus();
    } else {
      console.error("Popup blocked or couldn't be opened");
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const gmailSuccess = params.get('redi');
    if (gmailSuccess === "gmail_success") {
      navigate("/")
      connect(true);
    }
  }, [])

  const handleConnectDisconnect = async () => {
    if (isConnected) {
      connect(false);
    } else {
      setEmails([]);
      handleGmailConnect();
    }
  };

  const ExclusionHandle = () => {
    setEmailActiveTab("exclusions");
  };

  const handleSummary = () => {
    setEmailActiveTab("summary");
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
    setShowConnectAlert(false);
  };

  const isEmailExceedsLimit = (email) => {
    const charLimit = 14000;
    return email.content.length > charLimit;
  };

  return (
    <>
      <div className="rounded-xl mt-10 pb-20 shadow border border-zinc-300  min-h-500 bg-lotion  max-md:overflow-x-auto md:overflow-hidden scrollbar-width scrollable-container">
        <div className="mb-2   flex gap-2 justify-between items-center text-center">
          <div></div>
          <div className="flex items-center gap-x-2 ml-16 text-black text-opacity-80 text-xl font-bold font-['DM Sans']">
            <img src={gmailImage} alt="gmailImage" className="h-8 w-8 " />
            Gmail
          </div>
          <FormControlLabel
            sx={{
              display: 'block',
            }}
            control={
              <SwitchBtn
                checked={isConnected}
                onChange={handleConnectDisconnect}
                name="connect"
                sx={{ m: 1 }}
              />
            }
          />
        </div>
        <div
          className={`flex border h-12 shadow-md border-zinc-300 text-black text-opacity-80 text-xl font-['DM Sans']  
           bg-lotion rounded-full   items-center justify-between mx-auto font-semibold mt-2 buttons`}
        >
          <button
            className={`${EmailActiveTab === "input" ? "border rounded-full shadow-xl" : ""
              } w-80 h-full`}
            style={{}}
            onClick={() => setEmailActiveTab("input")}
          >
            {" "}
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <RiArrowUpDownFill />
              </span>
              Input
            </span>{" "}
          </button>
          <button
            className={`${EmailActiveTab === "summary"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={handleSummary}
          >
            {" "}
            <span className="flex items-center justify-center  gap-x-6">
              {" "}
              <span className="text-2xl font-extralight">
                <ImProfile />
              </span>
              Summary
            </span>
          </button>
          <button
            className={`${EmailActiveTab === "exclusions"
              ? "border rounded-full shadow-xl"
              : ""
              } w-80 h-full`}
            onClick={ExclusionHandle}
          >
            <span className="flex items-center justify-center gap-x-6">
              {" "}
              <VscExclude className="text-2xl" /> Exclusions{" "}
            </span>
          </button>
        </div>

        {EmailActiveTab === "exclusions" && (
          <div className="flex flex-col     p-4 gap-2  ">
            <div className="flex gap-2">
              <input
                type="checkbox"
                name="limitLength"
                checked={emailExclude?.limitLength === 500 ? true : false}
                onChange={updateCheckPoint}
                value={500}
              />
              <label htmlFor="">Limit Items to 500 Char</label>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                name="limitLength"
                checked={emailExclude?.limitLength === 1000 ? true : false}
                onChange={updateCheckPoint}
                value={1000}
              />
              <label htmlFor="">Limit Items to 1000 Char</label>
            </div>
            <div className="flex gap-2">
              <input
                type="checkbox"
                checked={emailExclude?.excludeUnsubscribe}
                name="excludeUnsubscribe"
                onChange={updateCheckPoint}
              />
              <label htmlFor="excludeUnsubscribe">Exclude Unsubscribe Emails</label>
            </div>
          </div>
        )}

        {isConnected && EmailActiveTab === "input" && Emails?.length > 0 ? (
          loadingSentEmails ? (
            <div className="flex justify-center items-center min-h-300 gap-4">
              <img src={gmailImage} alt="gmailImage" className="h-8" />
              <p className="mt-3 font-medium">Loading sent emails..</p>
            </div>
          ) : (
            <div className="table-container">
              <table className="min-w-full   overflow-auto h-20 divide-y-2 rounded-xl mt-3 divide-gray-200 bg-lotion text-md ">
                <thead className="mt-10 ">
                  <tr>
                    <th className="whitespace-nowrap  px-4 font-semibold text-blue-700">
                      To
                    </th>
                    <th className="whitespace-nowrap px-4 py-2   font-semibold text-blue-700">
                      Thread Name
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Message Content
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-semibold text-blue-700">
                      Sent Time
                    </th>
                    <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900"></th>
                  </tr>
                </thead>

                <tbody className="divide-y  divide-gray-200 ">
                  {Emails?.map((event, index) => (
                    <>
                      <tr
                        key={index}
                        className={isEmailExceedsLimit(event) ? "bg-red-200" : ""}
                      >
                        <td className="whitespace-nowrap px-2 py-2 font-medium flex w-64 text-gray-700">
                          <div
                            className={`w-[8vw] h-[8vh] overflow-hidden ${isEmailExceedsLimit(event) ? "bg-red-200" : ""
                              }`}
                          >
                            <textarea
                              name=""
                              rows="1"
                              cols="1"
                              id=""
                              value={event.to.split("<")[0]}
                              onChange={(event) =>
                                handleTextareaChangeFrom(event, index)
                              }
                              className={`w-full h-full mt-3 p-2 min-h-[5vh] max-h-[30vh] resize-none max-w-full ${isEmailExceedsLimit(event)
                                ? "bg-red-200"
                                : "bg-lotion"
                                }`}
                            ></textarea>
                          </div>
                        </td>

                        <td
                          className={`px-2 py-2 text-gray-700 ${isEmailExceedsLimit(event) ? "bg-red-200" : ""
                            }`}
                        >
                          <div className="w-[20vw] max-w-full">
                            <textarea
                              name=""
                              rows="1"
                              cols="1"
                              id=""
                              value={event.subject}
                              onChange={(event) =>
                                handleTextareaChangeThread(event, index)
                              }
                              className={`w-full h-full p-2 ${isEmailExceedsLimit(event)
                                ? "bg-red-200"
                                : "bg-lotion"
                                }`}
                            ></textarea>
                          </div>
                        </td>
                        <td
                          className={`px-2 py-2 text-gray-700 ${isEmailExceedsLimit(event) ? "bg-red-200" : ""
                            }`}
                        >
                          <div className="w-[20vw] h-[8vh] mb-12   ">
                            <textarea
                              name=""
                              rows="1"
                              cols="1"
                              id=""
                              value={event.content}
                              onChange={(event) =>
                                handleTextareaChangeContent(event, index)
                              }
                              className={`w-full h-full p-2 ${isEmailExceedsLimit(event)
                                ? "bg-red-200"
                                : "bg-lotion"
                                }`}
                            ></textarea>
                          </div>
                        </td>
                        <td className="flex-wrap pl-6 py-2 font-medium text-gray-700  max-w-full ">
                          {convertToIST(event?.time)}
                        </td>
                        <td>
                          <i
                            className="fa fa-trash"
                            onClick={() => handleDeleteEmails(index)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          )
        ) : EmailActiveTab === "input" ? (
          <div className="flex justify-center items-center min-h-300 gap-4">
            <img src={gmailImage} alt="gmailImage" className="h-8" />
            {loadingSentEmails ? (
              <p className="mt-3 font-medium">Loading sent emails..</p>
            ) : (
              <p className="mt-3 font-medium">Sent emails not found.</p>
            )}
          </div>
        ) : (
          ""
        )}
        {isConnected && EmailActiveTab === "summary" && (
          <>
            {loadingSummary ? (
              <div className=" loader-outer z-99999">
                <div className=" loader z-999999"></div>
              </div>
            ) : (
              ""
            )}
            <div>
              {/* <p className=" w-[60rem] m-auto pt-10 whitespace-pre-wrap"> */}
              <p className="w-[70%] m-auto p-3 whitespace-pre-wrap overflow-auto h-[24rem]">
                {Emails.length > 0 ? <span>{summaryData}</span> : <span></span>}
              </p>
            </div>
          </>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={isConnected ? "success" : "error"}>
          {isConnected
            ? "Hurray! You are connected."
            : "Connect to summarize Gmail Sent emails!"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EmailList;
